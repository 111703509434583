<template>
  <div class="auth-layout">
    <div class="auth-content">
      <router-view/>
    </div>

    <!-- Developed by -->
    <div class="developer">
      <DLink text="Developed by DTT | apps - web - games" link="https://www.d-tt.nl/en/" class="auth-link d-link-black"/>
    </div>
  </div>
</template>

<script>
import DLink from "@/components/elements/basicUI/DLink";
export default {
  name: "Layout",
  components: {DLink}
}
</script>

<style lang="scss">
@import "../../assets/css/base.mixins";

/** Layout **/
.auth-layout {
  align-items: center;
  background-color: var(--gray-main);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden auto;
  width: 100vw;
}

.auth-content {
  background-color: white;
  border-radius: rem(20);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
  //height: 80%;
  margin: rem(80) auto;
  //max-height: rem(773);
  max-width: rem(1000);
  text-align: center;
  width: 85%;
}

.auth-page-wrapper {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: rem(80) 30% rem(48) 30%;
}

/** Texts **/
.developer {
  margin-bottom: rem(40);
}

.auth-link {
  text-decoration: underline;
}

/** Buttons **/
.auth-button {
  width: 100%;
}

/** Images/icons **/
.auth-logo {
  height: rem(102);
  margin-bottom: rem(58);
  width: rem(232);
}
</style>
